import Head from 'next/head'
import Image from 'next/image'

{/* importing components */}
import ErrorPage from '../components/ErrorPage'
{/* finished importing components */}


export default function Home() {
  return (
    <>
        <Head>
            <title>Combat Warriors</title>
            <meta name="description" content="Welcome to the official Combat Warriors website! Read our blog, check out our wiki, and connect with other players!" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
            <meta name="theme-color" content="#000000" />
            <meta property="og:title" content="Combat Warriors" />
            <meta property="og:description" content="Welcome to the official Combat Warriors website! Read our blog, check out our wiki, and connect with other players!" />
            <meta property="og:image" content="https://assets.combatwarriors.gg/new_og_image.png" />
            <meta property="og:url" content="https://combatwarriors.gg" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Combat Warriors" />
            <meta name="twitter:card" content="summary_large_image" />
        </Head>
      
        <ErrorPage error={{ code: 404, message: "Sorry, we couldn't find that page." }} showLogin={false} />
    </>
  )
}
